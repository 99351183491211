<template>
	<div>
		<scNav navTag="scAbout" :themeColor="themeColor"></scNav>
		<div style="max-width: 1440px;margin: 0 auto;">
			<crumbs :crumbs="['首页','关于我们']"></crumbs>
		</div>
		<div class="sc-width">
			<tap :taps="['关于我们']" :bgColor="themeColor"><i></i></tap>
			<div class="tle">{{about.title}}</div>
			<div v-html="about.subject"></div>
		</div>
		<bottom :bgColor="themeColor"></bottom>
	</div>
</template>

<script>
	import centerIntroduce from "@/components/about/centerIntroduce"
	import scNav from '@/components/special/scNav'
	export default {
		data() {
			return {
				crumbs:['首页','关于我们'],
				themeColor:"",
				catid:0,
				about:{}
			}
		},
		components:{
			centerIntroduce,
			scNav
		},
		mounted() {
			this.$nextTick(()=>{
				this.setHtmlFz()
			})
			window.onresize = ()=>{
				this.setHtmlFz()
			}
		},
		created() {
			let type = this.$route.query.type
			if(type==1) {
				// 科技创新版
				this.themeColor = "#386eb8"
				this.catid = 123
			}else if(type==2) {
				
				// 专精特新版
				this.themeColor = "#47a8c8"
				this.catid = 132
			}else if(type==3) {
				// 交易版
				this.themeColor = "#d3a21e"
				this.catid = 140
			}
			this.getNewList(type)
		},
		methods:{
			async getNewList(type) {
				let url = ""
				if(type==1) {
					url = this.$api.base+"Specialization/getList"
				}else if(type==2) {
					url = this.$api.base+"Technology/getList"
				}else if(type==3) {
					url = this.$api.base+"Trading/getList"
				}
				let params = {
					catid:this.catid,
					limit:10,
					page:this.page
				}
				let res = await this.$axios.get(url,{params})
				if(res.code) {
					this.about = res.data.data[0]
				}
			},
			setHtmlFz() {
				// 获取html
				var html = document.documentElement;
				// 获取html的宽度
				var windowWidth = html.clientWidth;
				// 设置html的字体大小
				// 屏幕宽度/设计稿宽度
				html.style.fontSize = windowWidth /1920 * 100+ 'px'; 
			}
		}
	}
</script>

<style lang="less" scoped>
	.tle {
		font-size: 0.32rem;
		text-align: center;
		margin-bottom: 30px;
	}
	div {
		font-size: 16px;
	}
	@media (max-width:1000px) {
		.tle {
			font-size: 18px;
		}
		
	}
	
</style>